<script>
// import Layout from '../../layout/Layout'
import Menu from '../menu'
import {list, deleteBem} from '@/domain/bem/services'
import {listSimple as listPatiosSimple} from '@/domain/patio/services'
// import {list as listComitentes} from '../../../domain/comitentes/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage} from 'uloc-vue'
import {
  EBtnTableOptions,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import StatusBem from './include/StatusBem'
import ContextMenuBemItem from './context/list-context'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu'
import SearchFilter from '../../layout/helpers/SearchFilter'
import {ErpInput, ErpSelect, ErpSField} from 'uloc-vue-plugin-erp'
import StatusSelect from './include/StatusSelect'
import DateInput from '../../../reuse/input/Date'
import bemWindow from '../windows/bem'
import ComitenteSelect from '../../comitente/helpers/input/ComitenteSelect'
import TipoBemSelect from './include/TipoSelect'
import MarcaSelect from './include/MarcaSelect'
import ModeloSelect from './include/ModeloSelect'
import CorSelect from './include/CorSelect'
import {datePtToEn} from '@/utils/date'
import listOptions from '../windows/listOptions'
import ImportacaoBemBtn from '../../leiloes/components/include/ImportacaoBemBtn'
import ECol from '../../layout/components/Col'
import ERow from '../../layout/components/Row'
import ImportacaoBemMixin from '../../leiloes/components/include/ImportacaoBemMixin'
import * as StatusBemConst from '../../../domain/bem/helpers/Status'
import {donwloadFile} from "@/utils/downloadFile"
import filtersData, {situacaoAll} from "@/components/bem/helpers/filtersData"
import tableColumnsCache from "@/utils/tableColumnsCache"
import UfSelect from "@/components/remocao/components/include/UfSelect"

const listVersion = 5
export const listName = 'bens.list'
export const listStorage = tableColumnsCache(listName, [
  {label: 'Foto', name: 'foto', active: true, sortable: true, ordering: 1, tipo: null},
  {label: 'Código', name: 'codigo', active: true, sortable: true, ordering: 1, tipo: null},
  {label: 'Tipo', name: 'tipo', active: true, sortable: true, ordering: 1, tipo: 'imovel'},
  {label: 'Matricula', name: 'matricula', active: true, sortable: true, ordering: 1, tipo: 'imovel'},
  {label: 'Processo', name: 'processo', active: true, sortable: true, ordering: 1, tipo: 'imovel'},
  {label: 'Comitente', name: 'comitente', active: true, sortable: true, ordering: 1, tipo: null},
  {label: 'Descrição', name: 'descricao', active: true, sortable: true, ordering: 1, tipo: null},
  {label: 'Placa', name: 'placa', active: true, sortable: true, ordering: 1, tipo: 'veiculo'},
  {label: 'Localização', name: 'localizacao', active: true, sortable: true, ordering: 1, tipo: 'veiculo'},
  {label: 'Entrada', name: 'dataEntrada', active: false, sortable: true, ordering: 1, tipo: 'veiculo'},
  {label: 'Saída', name: 'dataSaida', active: false, sortable: true, ordering: 1, tipo: 'veiculo'},
  {label: 'Marca', name: 'marca', active: false, sortable: true, ordering: 1, tipo: 'veiculo'},
  {label: 'Modelo', name: 'modelo', active: false, sortable: true, ordering: 1, tipo: 'veiculo'},
  {label: 'Ano', name: 'ano', active: false, sortable: true, ordering: 1, tipo: 'veiculo'},
  {label: 'Valor Mercado', name: 'valorMercado', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'Valor Avaliação', name: 'valorAvaliacao', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'Valor Mínimo', name: 'valorMinimo', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'Criado em', name: 'criadoEm', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'Criado por', name: 'criadoPor', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'Alterado em', name: 'alteradoEm', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'Alterado por', name: 'alteradoPor', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'UF', name: 'uf', active: true, sortable: true, ordering: 1, tipo: 'imovel'},
  {label: 'Cidade', name: 'cidade', active: true, sortable: true, ordering: 1, tipo: 'imovel'},
  {label: 'Bairro', name: 'bairro', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'Venda Direta', name: 'vendaDireta', active: false, sortable: true, ordering: 1, tipo: null},
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1, tipo: null}
], listVersion)

let filters = filtersData

export default {
  name: 'Bens',
  mixins: [ImportacaoBemMixin],
  props: {
    leilao: {
      required: false
    },
    processo: {
      required: false
    }
  },
  components: {
    UfSelect,
    ERow,
    ECol,
    ImportacaoBemBtn,
    CorSelect,
    ModeloSelect,
    MarcaSelect,
    TipoBemSelect,
    ComitenteSelect,
    DateInput,
    StatusSelect,
    SearchFilter,
    DefaultListMenu,
    MenuOptions,
    ContextMenuBemItem,
    StatusBem,
    // Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSelect,
    ErpSField,
    ErpCheckbox
  },
  data () {
    const f = JSON.parse(JSON.stringify(filters))
    f.data1 = this.$route && this.$route.query.data1 ? this.$route.query.data1 : null
    f.data2 = this.$route && this.$route.query.data2 ? this.$route.query.data2 : null
    f.comitente = this.$route ? this.$route.query.comitente ? this.$route.query.comitente : null : null
    f.origem = this.$route && typeof this.$route.query.origem !== 'undefined' ? Number(this.$route.query.origem) : null
    f.analiseCadastral = this.$route && typeof this.$route.query.analiseCadastral !== 'undefined' ? String(this.$route.query.analiseCadastral) : null
    const eventos = {
      'Disponíveis': 'disponivel',
      'Saneamento': 'saneamento',
      'Providências': 'providencia',
      'Impedidos': 'bloqueado',
      'Venda': 'venda',
      'Formalização': 'formalizacao',
    }
    f.evento = this.$route && typeof this.$route.query.evento !== 'undefined' ? eventos[this.$route.query.evento] ?? this.$route.query.evento : null
    return {
      listStorage: listStorage,
      tipoBem: null,
      tiposBem: null,
      listType: null,
      filtersData: {
        loadedAll: false,
        loadedAllError: false,
        comitentes: {
          loading: true,
          serverData: []
        },
        tipoBem: {
          loading: true,
          serverData: []
        },
        marca: {
          loading: true,
          serverData: []
        },
        modelo: {
          loading: true,
          serverData: []
        },
        cor: {
          loading: true,
          serverData: []
        },
        patio: {
          loading: true,
          serverData: []
        }
      },
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: this.leilao ? 100 : 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: f,
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    isImovel () {
      if (!this.tipoBem) return false
      if (this.tipoBem.parent && this.tipoBem.parent.codigo === 'imoveis') {
        return true
      }
      return this.tipoBem.codigo === 'imoveis'
    },
    isVeiculo () {
      if (!this.tipoBem) return false
      if (this.tipoBem.parent && this.tipoBem.parent.codigo === 'veiculos') {
        return true
      }
      return this.tipoBem.codigo === 'veiculos'
    },
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    },
    visibledColumns () {
      return this.listStorage.filter(item => item.active && ((!item.tipo || (this.isImovel && item.tipo === 'imovel') || (this.isVeiculo && item.tipo === 'veiculo')))).map(item => item.name)
    },
    lotesSelecionados () {
      if (!this.leilao || !this.leilao.lotes) {
        return []
      }
      let lotes = this.leilao.lotes.slice().map(lt => {
        return lt.bem.id
      })
      return [...lotes, ...this.cacheBensAdicionados]

    },
    filteredServerData () {
      if (this.leilao) {
        return this.table.serverData.filter(lt => !this.lotesSelecionados.includes(lt.id))
      }
      return this.table.serverData
    }
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        if (this.parseHash()) {
          this.load()
        }
      })
    },
    'table.filters.analiseCadastral' (v) {
      this.$nextTick(() => {
        if (this.table.filters.analiseCadastral !== null) {
          this.table.filters.situacao = []
        }
      })
    }
  },
  created () {
    this.loadPatios()
  },
  mounted () {
    if (this.processo) {
      this.table.filters.situacao = JSON.parse(JSON.stringify(situacaoAll))
    }
    this.parseHash()
    this.load()
  },
  destroyed () {
    // document.querySelector('.ulotes-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    parseHash () {
      if (this.$route.hash) {
        const hash = this.$route.hash.replace('#', '')
        if (hash === 'imoveis') {
          this.listType = 'Imóveis'
          //this.load()
        }
        if (hash === 'veiculos') {
          this.listType = 'Veículos'
          //this.load()
        }
        this.autoSelectTipoBem()
      } else {
        this.listType = null
        this.tipoBem = null
        this.table.filters.tipoBem = null
        this.load()
        this.$nextTick(() => {
          this.autoSelectTipoBem()
        })
      }
    },
    request ({pagination, filter}, exportar = null) {

      let data1, data2
      let extraFilters = []
      /*
      if (this.filtros.tipoFiltroData === '-1' && (!this.filtros.data1 || !this.filtros.data2)) {
        alert('Informe um intervalo de datas para processar o filtro')
        return
      }*/
      if (this.table.filters.data1) {
        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2 || ''}&tipoData=${this.table.filters.tipoData}`)
      }

      this.table.filters.comitente && extraFilters.push(`&comitentes=${this.table.filters.comitente}`)
      this.table.filters.patio && extraFilters.push(`&patio=${this.table.filters.patio}`)
      this.table.filters.codigo && extraFilters.push(`&id=${this.table.filters.codigo}`)
      this.table.filters.tipoBem && extraFilters.push(`&tipo=${this.table.filters.tipoBem}`)
      this.table.filters.marca && extraFilters.push(`&marca=${this.table.filters.marca}`)
      this.table.filters.modelo && extraFilters.push(`&modelo=${this.table.filters.modelo}`)
      this.table.filters.ano && extraFilters.push(`&ano=${this.table.filters.ano}`)
      this.table.filters.placa && extraFilters.push(`&placa=${this.table.filters.placa}`)
      this.table.filters.chassi && extraFilters.push(`&chassi=${this.table.filters.chassi}`)
      this.table.filters.cor && extraFilters.push(`&cor=${this.table.filters.cor}`)
      this.table.filters.situacao && extraFilters.push(`&status=${this.table.filters.situacao}`)
      this.table.filters.codigoFipe && extraFilters.push(`&codigoFipe=${this.table.filters.codigoFipe}`)
      this.table.filters.bloqueadoLeilao && extraFilters.push(`&bloqueadoLeilao=${this.table.filters.bloqueadoLeilao}`)
      this.table.filters.alagamento && extraFilters.push(`&alagamento=1`)
      this.table.filters.processoInteligente && extraFilters.push(`&processoInteligente=${this.table.filters.processoInteligente}`)
      this.table.filters.numeroSinistro && extraFilters.push(`&numeroSinistro=${this.table.filters.numeroSinistro}`)
      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)
      this.processo && extraFilters.push(`&processo=${this.processo && this.processo.id ? this.processo.id : this.processo}`)

      this.table.filters.matricula && extraFilters.push(`&matricula=${this.table.filters.matricula}`)
      this.table.filters.vendaDireta !== null && extraFilters.push(`&vendaDireta=${this.table.filters.vendaDireta}`)
      this.table.filters.uf && extraFilters.push(`&uf=${this.table.filters.uf}`)
      this.table.filters.cidade && extraFilters.push(`&cidade=${this.table.filters.cidade}`)
      this.table.filters.endereco && extraFilters.push(`&endereco=${this.table.filters.endereco}`)
      this.table.filters.bairro && extraFilters.push(`&bairro=${this.table.filters.bairro}`)
      this.table.filters.cep && extraFilters.push(`&cep=${this.table.filters.cep}`)

      this.table.filters.analiseCadastral && extraFilters.push(`&analiseCadastral=${this.table.filters.analiseCadastral}`)
      null !== this.table.filters.origem && extraFilters.push(`&origem=${this.table.filters.origem}`)
      this.table.filters.evento && extraFilters.push(`&evento=${this.table.filters.evento}`)

      if (exportar) {
        extraFilters.push(`&export=${exportar}`)
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, exportar)
        .then((response) => {
          console.log(response)
          if (exportar) {
            donwloadFile(response)
            this.table.loading = false
            return
          }
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = response.data.total
          this.table.serverData = response.data.result

          this.table.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    load () {
      const request = () => {
        this.request({
          pagination: this.table.serverPagination,
          filter: this.table.busca
        })
      }
      if (this.leilao) {
        this.table.filters.situacao = [StatusBemConst.STATUS_CADASTRADO, StatusBemConst.STATUS_EM_REMOCAO, StatusBemConst.STATUS_NO_PATIO]
        console.log(this.leilao.comitentes, this.leilao)
        if (this.leilao.comitentes) {
          this.table.filters.comitente = this.leilao.comitentes.slice().map(c => c.id)
          this.$on('loadedComitente', () => {
            request()
          })
        }
      } else {
        request()
      }
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      this.bemWindow()
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filter
      })
    },
    exportar (tipo) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      }, 'excel')
    },
    bemWindow: bemWindow,
    listOptions: listOptions,
    updateListDefinition (newConfig) {
      LocalStorage.set('bens.list', newConfig)
      this.listStorage = newConfig
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este bem? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir bem-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteBem(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Bem excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    loadPatios () {
      listPatiosSimple()
          .then (response => {
            this.filtersData.patio.serverData = response.data.map(p => ({label: p.nome, value: p.id}))
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    loadTiposBem (result) {
      this.tiposBem = result
      this.autoSelectTipoBem()
    },
    autoSelectTipoBem () {
      this.$nextTick(() => {
        if (this.listType && this.tiposBem) {
          const find = this.tiposBem.find(t => {
            return this.listType.toLowerCase() == t.nome.toLowerCase()
          })
          if (typeof find !== 'undefined' && find.id)  {
            this.table.filters.tipoBem = find.id
            this.load()
          }
        }
      })
    }
  },
  meta: {
    title: 'Bens',
    name: 'Bens'
  }
}
</script>

<template>
  <!--<layout class="erp-list" :menu="menu" menu-style="compact" menu-active="bens">-->
  <div :class="{'wrapper-md': leilao}">
    <search-filter>
      <form @keydown.enter="pesquisar" id="bemFilter" class="m-t-sm">
        <e-row mr>

          <e-col style="max-width: 130px; min-width: 130px">
            <tipo-bem-select
                @selected="(t) => tipoBem = t"
                ref="tipoPai"
                placeholder="Qualquer"
                label="Tipo de bem:"
                field-view="tl"
                list-all=""
                @load="loadTiposBem"
                v-model="table.filters.tipoBem" />
          </e-col>

          <e-col style="max-width: 175px; min-width: 175px">
            <erp-s-field
                view="tl"
                label="Pesquisa inteligente"
            >
              <erp-input @keydown.enter="pesquisar" shortkey="F2" v-model="table.filters.search" />
            </erp-s-field>
          </e-col>

          <e-col v-if="isVeiculo" style="max-width: 180px; min-width: 130px">
            <marca-select placeholder="Selecione" label-width="110px"
                          label="Montadora/Marca:"
                          disable-create
                          v-model="table.filters.marca" />
          </e-col>

          <e-col v-if="isVeiculo" class="col" style="max-width: 180px; min-width: 130px">
            <modelo-select
                :disable="!table.filters.marca"
                :marca="table.filters.marca"
                placeholder="Selecione"
                label="Modelo:"
                disable-create
                v-model="table.filters.modelo" />
          </e-col>

          <e-col v-if="isVeiculo" style="max-width: 75px; min-width: 75px">
            <erp-s-field
                view="tl"
                label="Ano Modelo"
            >
              <erp-input v-model="table.filters.ano" />
            </erp-s-field>
          </e-col>

          <e-col v-if="isVeiculo" style="max-width: 175px; min-width: 75px">
            <erp-s-field
                view="tl"
                label="Placa"
            >
              <erp-input shortkey="F3" v-model="table.filters.placa" />
            </erp-s-field>
          </e-col>

          <e-col v-if="isVeiculo" style="min-width: 120px; max-width: 200px">
            <erp-s-field
                view="tl"
                label="Chassi"
            >
              <erp-input v-model="table.filters.chassi" />
            </erp-s-field>
          </e-col>

          <!-- NL -->

          <e-col v-if="isVeiculo" style="max-width: 130px; min-width: 130px">
            <cor-select
                placeholder="Qualquer"
                label="Cor predominante:"
                v-model="table.filters.cor" />
          </e-col>

          <e-col style="max-width: 195px; min-width: 295px">
            <!--<erp-s-field
                view="tl"
                label="Comitente"
            >
              <erp-select
                  multiple
                  :loading="filtersData.comitentes.loading"
                  :placeholder="'Qualquer'"
                  :options="filtersData.comitentes.serverData"
                  :columns="[
                    {label: 'Nome', value: 'nome'},
                    {label: 'Tipo', value: 'tipoName'}
                  ]"
                  v-model="table.filters.comitente" />
            </erp-s-field>-->
            <comitente-select label="Cliente" @loaded="$emit('loadedComitente')" multiple :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="table.filters.comitente" />
          </e-col>

          <e-col v-if="isVeiculo" style="min-width: 100px">
            <erp-s-field
                view="tl"
                label="Código Fipe"
            >
              <erp-input v-model="table.filters.codigoFipe" />
            </erp-s-field>
          </e-col>

          <e-col v-if="isVeiculo" style="max-width: 130px; min-width: 130px">
            <erp-s-field
                view="tl"
                label="Pátio"
            >
              <erp-select
                  placeholder="Qualquer"
                  :options="filtersData.patio.serverData"
                  v-model="table.filters.patio" />
            </erp-s-field>
          </e-col>

<!--          <div class="col" style="min-width: 174px">
            <erp-s-field
                view="tl"
                label="Busca inteligente"
            >
              <erp-input @keydown.enter="pesquisar" shortkey="F4" v-model="table.filter" />
            </erp-s-field>
          </div>-->

          <e-col style="min-width: 300px; max-width: 400px">
            <status-select multiple v-model="table.filters.situacao" />
          </e-col>

          <e-col v-if="!processo" style="min-width: 130px; max-width: 200px">
            <erp-s-field
                view="tl"
                label="Processo"
            ><!-- @TODO /Contrato/Partes -->
              <erp-input v-model="table.filters.processoInteligente" />
            </erp-s-field>
          </e-col>

          <e-col style="max-width: 200px" v-if="isImovel">
            <erp-s-field
                view="tl"
                label="Matrícula"
            >
              <erp-input v-model="table.filters.matricula" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 130px; min-width: 130px">
            <erp-s-field
                view="tl"
                label="Venda direta"
            >
              <erp-select
                  placeholder="Qualquer"
                  :options="[{label: 'Qualquer', value: null},{label: 'Sim', value: 1},{label: 'Não', value: 0}]"
                  v-model="table.filters.vendaDireta" />
            </erp-s-field>
          </e-col>

          <e-col style="max-width: 130px; min-width: 130px">
            <erp-s-field
                view="tl"
                label="Evento"
            >
              <erp-select
                  placeholder="Qualquer"
                  :options="[{label: 'Qualquer', value: null},{label: 'Disponíveis', value: 'disponivel'},{label: 'Saneamento', value: 'saneamento'},{label: 'Providências', value: 'providencia'},{label: 'Impedidos', value: 'bloqueado'},{label: 'Venda', value: 'venda'},{label: 'Formalização', value: 'formalizacao'}]"
                  v-model="table.filters.evento" />
            </erp-s-field>
          </e-col>

          <!--<div class="col" style="min-width: 130px">
            <erp-s-field
                view="tl"
                label="Remessa"
            >
              <erp-input v-model="table.filters.remessa" />
            </erp-s-field>
          </div>-->

        </e-row>
        <e-row mr>
          <e-col style="max-width: 200px" v-if="isImovel">
            <uf-select v-model="table.filters.uf" />
          </e-col>
          <e-col style="max-width: 200px" v-if="isImovel">
            <erp-s-field
                view="tl"
                label="Cidade"
            >
              <erp-input v-model="table.filters.cidade" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px" v-if="isImovel">
            <erp-s-field
                view="tl"
                label="Endereço"
            >
              <erp-input v-model="table.filters.endereco" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px" v-if="isImovel">
            <erp-s-field
                view="tl"
                label="Bairro"
            >
              <erp-input v-model="table.filters.bairro" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px" v-if="isImovel">
            <erp-s-field
                view="tl"
                label="CEP"
            >
              <erp-input v-model="table.filters.cep" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col v-if="isVeiculo" style="max-width: 130px">
            <erp-s-field
                view="tl"
                label="Sinistro"
            >
              <erp-input v-model="table.filters.numeroSinistro" />
            </erp-s-field>
          </e-col>

          <e-col style="max-width: 110px; min-width: 110px">
            <erp-s-field
                view="tl"
                label="Código do Bem"
            >
              <erp-input v-model="table.filters.codigo" />
            </erp-s-field>
          </e-col>

          <e-col style="min-width: 126px; max-width: 126px">
            <date-input label="Data (De)" v-model="table.filters.data1" />
          </e-col>

          <e-col class="col" style="min-width: 126px; max-width: 126px">
            <date-input label="Data (Até)" v-model="table.filters.data2" />
          </e-col>
          <e-col style="max-width: 130px; min-width: 130px">
            <erp-s-field
                view="tl"
                label="Tipo Data"
            >
              <erp-select
                  placeholder="Qualquer"
                  :options="[{label: 'Cadastro', value: 'cadastro'},{label: 'Entrada', value: 'entrada'},{label: 'Saída', value: 'saida'},{label: 'Última alteração', value: 'alteracao'}]"
                  v-model="table.filters.tipoData" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 130px; min-width: 130px">
            <erp-s-field
                view="tl"
                label="Análise Cadastral"
            >
              <erp-select
                  placeholder="Qualquer"
                  :options="[{label: 'Qualquer', value: null},{label: 'Análise', value: 'analise'},{label: 'Bloqueado', value: 'bloqueado'},{label: 'Disponível', value: 'disponivel'},{label: 'Proposta', value: 'proposta'},{label: 'Vendido', value: 'vendido'}]"
                  v-model="table.filters.analiseCadastral" />
            </erp-s-field>
          </e-col>

          <e-col style="max-width: 130px; min-width: 130px">
            <erp-s-field
                view="tl"
                label="Origem"
            >
              <erp-select
                  placeholder="Qualquer"
                  :options="[{label: 'Qualquer', value: null},{label: 'Formulário interno', value: 0},{label: 'Robô', value: 1},{label: 'API', value: 2}]"
                  v-model="table.filters.origem" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="tl"
                label="Bloqueado para venda"
            >
              <erp-checkbox v-model="table.filters.bloqueadoLeilao" />
            </erp-s-field>
          </e-col>

          <e-col v-if="isVeiculo">
            <erp-s-field
                view="tl"
                label="Prov. Alagamento"
            >
              <erp-checkbox v-model="table.filters.alagamento" />
            </erp-s-field>
          </e-col>

          <div class="col flex justify-end m-t-sm def-list-buttons" style="min-width: 300px">
            <div class="flex flex-center column m-r-xs">
              <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                     @click="pesquisar" />
              <span class="shortcut">Shift + Enter</span>
            </div>
            <div class="flex flex-center column m-r-xs">
              <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                     @shortkey.native="clearFilters" />
              <span class="shortcut">Ctrl + Shift + R</span>
            </div>
            <div class="flex flex-center column" v-if="cracl(null, ['sl/bem/create'])">
              <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                     @shortkey.native="novo" />
              <span class="shortcut">Shift + N</span>
            </div>
          </div>
        </e-row>

        <e-row>
          <e-col v-if="leilao" class="text-negative font-11 font-bold">
            Listando somente lotes que ainda não foram incluídos no leilão <u>{{leilao.codigo}}</u>
          </e-col>
        </e-row>
        <importacao-bem-btn :loading="loadingAdicionar" @adicionar="adicionar" v-if="leilao && table.selected.length > 0" />
      </form>
    </search-filter>
    <div class="erp-list">
      <div class="pull-left font-12">
        {{ table.serverPagination.rowsNumber }} registros encontrados
      </div>
      <div class="text-right">
        <u-btn @click="exportar('excel')" flat icon="file-excel" icon-type="fa" icon-style="light" title="Exportar para excel" label="Excel" size="sm" no-caps />
        <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8" />
      </div>
      <u-table
          ref="table"
          color="primary"
          :data="filteredServerData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[20, 50, 100]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="bemWindow(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
              :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs" />
          </u-td>
          <u-td key="foto" :props="props" auto-width>
            <img v-if="props.row.image && props.row.image.thumb && props.row.image.thumb.url" :src="props.row.image.thumb.url" width="100" />
            <div v-else class="noimg bg-blue-grey-1 h-full w-full flex flex-center items-center justify-center column text-grey-6 no-select" style="width: 100px; height: 100px">
              <div>
                <u-icon name="image"/>
              </div>
              Sem imagem
            </div>
          </u-td>
          <u-td key="codigo" :props="props" auto-width>
            {{props.row.id}} <!-- CODIGO ? -->
          </u-td>
          <u-td :title="props.row.tipo ? props.row.tipo.nome : ''" class="td-limit l-130"
                key="tipo"
                :props="props">
            {{props.row.tipo && props.row.tipo.nome ? props.row.tipo.nome :
              props.row.tipo}}
          </u-td>
          <u-td key="matricula" :props="props">
            {{props.row.identificador}}
          </u-td>
          <u-td key="processo" :props="props">
            {{props.row.processoNumero|formataNumeroProcesso}} <!-- CODIGO ? -->
          </u-td>
          <u-td :title="props.row.comitente ? props.row.comitente.pessoa.name : ''" class="td-limit l-130"
                key="comitente"
                :props="props">
            {{props.row.comitente && props.row.comitente.pessoa ? props.row.comitente.pessoa.name :
            props.row.comitente}}
          </u-td>
          <u-td class="td-limit l-130" key="descricao" :props="props">
            {{props.row.descricao}}
          </u-td>
          <u-td key="placa" :props="props">
            {{props.row.placa}}
          </u-td>
          <u-td key="localizacao" :props="props">
            <div v-if="props.row.patio">Pátio: {{props.row.patio.nome}} ({{props.row.patio.sigla}})</div>
            <div v-if="props.row.vaga">Vaga: {{props.row.vaga.codigo}}</div>
            <div>{{props.row.local}}</div>
          </u-td>
          <u-td key="dataEntrada" :props="props">
            <span v-if="props.row.dataEntrada">{{ props.row.dataEntrada.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="dataSaida" :props="props">
            <span v-if="props.row.dataSaida">{{ props.row.dataSaida.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="marca" :props="props">
            {{props.row.marca ? props.row.marca.nome : '-'}}
          </u-td>
          <u-td key="modelo" :props="props">
            {{props.row.modelo ? props.row.modelo.nome : '-'}}
          </u-td>
          <u-td key="ano" :props="props">
            {{props.row.anoFabricacao}}/{{props.row.anoModelo}}
          </u-td>
          <u-td key="valorMercado" :props="props">
            R$ {{props.row.valorMercado|moeda}}
          </u-td>
          <u-td key="valorAvaliacao" :props="props">
            R$ {{props.row.valorAvaliacao|moeda}}
          </u-td>
          <u-td key="valorMinimo" :props="props">
            R$ {{props.row.valorMinimo|moeda}}
          </u-td>
          <u-td key="criadoEm" :props="props">
            {{props.row.createdAt|formatDate}}
          </u-td>
          <u-td key="criadoPor" :props="props">
            {{props.row.createdByName}}
          </u-td>
          <u-td key="alteradoEm" :props="props">
            {{props.row.dateModified|formatDate}}
          </u-td>
          <u-td key="alteradoPor" :props="props">
            {{props.row.modifiedByUser}}
          </u-td>
          <u-td key="uf" :props="props">
            {{props.row.uf}}
          </u-td>
          <u-td key="cidade" :props="props">
            {{props.row.cidade}}
          </u-td>
          <u-td key="bairro" :props="props">
            {{props.row.bairro}}
          </u-td>
          <u-td key="vendaDireta" :props="props">
            {{props.row.vendaDireta ? 'Sim' : 'Não'}}
          </u-td>
          <u-td class="text-uppercase" key="status" :props="props">
            <status-bem :bem="props.row" />
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <default-list-menu @abrir="bemWindow(props.row.id)" @excluir="excluir(props.row.id)" :bem="props.row" />
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <context-menu-bem-item @abrir="bemWindow(props.row.id)" @excluir="excluir(props.row.id)" :bem="props.row" />
        </u-tr>
      </u-table>
    </div>

  </div>
  <!--</layout>-->
</template>

<!--<style lang="stylus" src="../assets/default.styl"></style>-->
